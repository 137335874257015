// src/pages/PrivacyPolicyPage.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
	return (
		<div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
			<h1>Privacy Policy for KenbukaiPH</h1>

			<section>
				<h2>Introduction</h2>
				<p>
					KenbukaiPH ("we", "us", or "our") respects your privacy and is committed to protecting the personal
					information you share with us.
				</p>
			</section>

			<section>
				<h2>Information We Collect</h2>
				<p>
					We may collect personal information, including your camera permissions, for the following purposes:
				</p>
				<ul>
					<li>Camera access for taking pictures or recording videos within the app.</li>
				</ul>
			</section>

			<section>
				<h2>How We Use Your Information</h2>
				<p>We use the information for the following:</p>
				<ul>
					<li>Enabling the use of camera functionality within the app.</li>
					<li>To improve the user experience.</li>
				</ul>
			</section>

			<section>
				<h2>Data Sharing</h2>
				<p>We do not share your personal information with third parties unless required by law.</p>
			</section>

			<section>
				<h2>Data Security</h2>
				<p>We take reasonable measures to protect your personal information.</p>
			</section>

			<section>
				<h2>Your Rights</h2>
				<p>You have the right to access, update, or request the deletion of your personal information.</p>
			</section>

			<section>
				<h2>Contact Us</h2>
				<p>For any privacy-related questions, please contact us at:</p>
				<p>Email: <a href="mailto:delacruzjamesmartin@gmail.com">delacruzjamesmartin@gmail.com</a></p>
			</section>
		</div>
	);
};

export default PrivacyPolicy;
